import axios from 'services/api/axios';
import {
  Bundle,
  BundleCSVRequestData,
  BundleResponseData,
  BundleStringRequestData,
  NewBundleCreateRequestBody,
  NewBundleCreateResponse,
} from '../types/bundles';
import { ListItem } from '../types/lists';
import { Pagination } from '../types/pagination';

export default {
  get: async (id: number, queryString?: string): Promise<Pagination<Bundle>> => {
    const response = await axios.get(`/lists/${id}/bundles?${queryString}`);

    return response.data;
  },

  newGet: async (id: number, queryString?: string): Promise<Pagination<Bundle>> => {
    const response = await axios.get(`/new-lists/${id}/bundles?${queryString}`);

    return response.data;
  },
  getListWithoutPagination: async (id: number): Promise<Pagination<Bundle>> => {
    const response = await axios.get(`/lists/${id}/bundles?$limit=10000`);

    return response.data;
  },
  newCreate: async (id: ListItem['id'], data: NewBundleCreateRequestBody): Promise<NewBundleCreateResponse> => {
    const response = await axios.post(`/new-lists/${id}/bundles`, data);

    return response.data;
  },

  newDelete: async (id: number, bundlesIds: number[]): Promise<any> => {
    const response = await axios.delete(`/new-lists/${id}/bundles?${bundlesIds.map((id) => `ids=${id}`).join('&')}`);

    return response.data;
  },

  newCreateFromCSV: async (id: ListItem['id'], formData: FormData): Promise<NewBundleCreateResponse> => {
    const response = await axios.post(`/new-lists/${id}/bundles-upload`, formData);

    return response.data;
  },
  createFromString: async (id: ListItem['id'], data: BundleStringRequestData): Promise<BundleResponseData> => {
    const response = await axios.post(`/lists/${id}/bundles-upload/string`, data);

    return response.data;
  },
  createFromCSV: async (id: ListItem['id'], data: BundleCSVRequestData): Promise<BundleResponseData> => {
    const response = await axios.post(`/lists/${id}/bundles-upload/csv`, data);

    return response.data;
  },
  downloadCSV: async (id: ListItem['id']): Promise<any> => {
    const response = await axios.get(`/new-lists/${id}/bundles-download`);

    return response.data;
  },
  delete: async (id: number, queryString?: string): Promise<void> => {
    const response = await axios.delete(`/lists/${id}/bundles?${queryString}`);

    return response.data;
  },
};
